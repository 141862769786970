@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 210, 96%, 8%;
    --primary: 211 95% 9% 1;
    --primary-foreground: 0 0% 98%;
    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;
    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;
    --accent: 211, 95%, 9%, 1;
    --accent-foreground: 173, 100%, 54%, 1;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;
    --chart-1: 0 100% 40%;
    --chart-2: 29 100% 55%;
    --chart-3: 43 86% 60%;
    --chart-4: 154 80% 33%;
    --chart-5: 211 95% 9%;
    --radius: 0.5rem;
    --bg-critical: 0 100% 40%;
    --bg-alarm: 29 100% 55%;
    --bg-monitor: 43 86% 60%;
    --bg-healthy: 154 80% 33%;
    --bg-not-monitored: 211 95% 9%;
    --bg-critical-light: 0, 100%, 40%, 0.12;
    --bg-alarm-light: 29, 100%, 55%, 0.12;
    --bg-monitor-light: 43, 86%, 60%, 0.12;
    --bg-healthy-light: 154, 80%, 33%, 0.12;
    --bg-not-monitored-light: 211, 95%, 9%, 0.12;
    --sidebar-background: 211 95% 9% 1;
    --sidebar-foreground: 240 5.3% 26.1%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 240 4.8% 95.9%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;
    --header-backgorund: 0 0% 100% 1;

    /* text */
    --dm-text-primary: #01152b;
    --dm-text-subtext: #55687d;
    --dm-text-disabled: #b1b9c3;
    --dm-text-dark: #01152b;
    --dm-text-light: #ffffff;
    /* border */
    --border: #55687d;
    /* dm_primary */
    --dm-primary-50: #f1f1fe;
    --dm-primary-100: #d3d3fb;
    --dm-primary-200: #bdbef9;
    --dm-primary-300: #9fa0f7;
    --dm-primary-400: #8d8df5;
    --dm-primary-500: #7071f3;
    --dm-primary-600: #6667dd;
    --dm-primary-700: #5050ad;
    --dm-primary-800: #3e3e86;
    --dm-primary-900: #2f2f66;

    /* dm_variant */
    --dm-variant-50: #e7fffc;
    --dm-variant-100: #b6fff6;
    --dm-variant-200: #92fff2;
    --dm-variant-300: #60ffec;
    --dm-variant-400: #41ffe8;
    --dm-variant-500: #12ffe2;
    --dm-variant-600: #10e8ce;
    --dm-variant-700: #0db5a0;
    --dm-variant-800: #0a8c7c;
    --dm-variant-900: #086b5f;

    /* dm_neutral */
    --dm-neutral-25: #F1F2F4;
    --dm-neutral-50: #e6e8ec;
    --dm-neutral-100: #b1b9c3;
    --dm-neutral-200: #8b97a6;
    --dm-neutral-300: #354a64;
    --dm-neutral-400: #41ffe8;
    --dm-neutral-500: #021d3d;
    --dm-neutral-600: #021a38;
    --dm-neutral-700: #01152b;
    --dm-neutral-800: #011022;
    --dm-neutral-900: #010c1a;

    /*surface*/
    --dm-surface-hover: '#B1B9C34D';
  }
  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;
    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;
    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;
    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 0% 83.1%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
    --sidebar-background: 240 5.9% 10%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 224.3 76.3% 48%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
}
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.js-plotly-plot .modebar {
  padding-top:8px !important; 
}
.js-plotly-plot .modebar-container {
  background-color: rgba(0, 0, 0, 0.5); /* Make it semi-transparent */
  border-radius: 8px; /* Add rounded corners */
}

*{
  font-size: 14px;
} 